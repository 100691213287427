<template>
  <div class="page page--terms" :class="{ rtl: isRtl }">
    <Header :hasClose="true" />
    <div class="page__content">
      <h4
        class="page__title"
        :dir="textDirection"
        v-if="getConfig.terms_description_text"
         >{{ customizationText }}</h4>
      <h4 class="page__title" :dir="textDirection" v-else>
        <span class="page__title-highlight">
          {{clientTitle}}
        </span>
        <Translate :translate="'termsPageTitle'" />
      </h4>
      <div class="page__details" id="pageScroll" ref="pageScroll">
        <div ref="pageRich" class="page__rich" :class="{
          'page__rich--expand': showMoreButton,
          'page__rich--small': showMore && !showMoreButton,
        }">
          <div v-show="getConfig?.richtext?.length > 0">
            <div v-html="getConfig.richtext" :dir="textDirection"></div>
            <div
              v-show="showMore"
              class="page__rich-more"
              :class="{ 'page__rich-more--rotate': showMoreButton }"
              @click="showMoreButton = !showMoreButton">
              Read more <ArrowIcon />
            </div>
          </div>
        </div>
          <div class="page__item" v-for="item in termsQA" :key="item.title" @click="item.expanded = !item.expanded">
            <div class="page__item-icon">
              <component :is="item.icon" class="info" />
            </div>
            <div class="page__item-title">
              <Translate :translate="item.title" />
              <div class="expand" :class="{ 'arrow--up': item.expanded }">
                <ArrowIcon />
              </div>
            </div>
            <div class="page__item-description">
              <p v-show="item.expanded">
                <Translate :translate="item.description" />&nbsp;
                <a
                  v-if="item.descriptionLink"
                  class="page__link"
                  @click="legalInfoModal(undefined)">
                  <Translate :translate="item?.descriptionLink" />
                </a>
              </p>
            </div>
          </div>
      </div>
    </div>
    <div class="page--terms-actions">
      <button
        class="button--block"
        :style="`background: ${getConfig.cta_button_backcolor};
                 color: ${getConfig.cta_button_forecolor}`"
        @click="goNext()">
          <Translate :translate="'termsFooterContinue'"/>
      </button>
      <TermsOfUse />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Switch from '@/components/Switch';
// eslint-disable-next-line
import KeyHoleIcon from '@/components/icons/KeyHoleIcon.vue';
// eslint-disable-next-line
import ProtectedIcon from '@/components/icons/ProtectedIcon.vue';
// eslint-disable-next-line
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue';
import TermsOfUse from '@/components/TermsOfUse';
import Localization from '@/mixins/localization';
import General from '@/mixins/general';
import ArrowIcon from '@/components/icons/ArrowIcon';

export default {
  components: {
    Header,
    Switch,
    Footer,
    ProtectedIcon,
    KeyHoleIcon,
    CheckmarkIcon,
    TermsOfUse,
    ArrowIcon,
  },
  mixins: [Localization,General],
  data() {
    return {
      el: null,
      showMoreButton: false,
      showMore: false,
      termsUrl: this.$store.state.translations.termsOfUseUrl,
      privacyUrl: this.$store.state.translations.privacyPolicyUrl,
      additionalTermsFor: this.$store.state.settings.additionalTerms,
      consent: {
        termsVersion: process.env.VUE_APP_TERMS_OF_USE_VERSION,
        partnerTitle: this.$store.state.config.partner_name,
      },
      termsQA: [
        {
          icon: 'KeyHoleIcon',
          title: 'termsPlatformTitle',
          description: 'termsPlatformDescription',
          descriptionLink: 'termsPlatformDescriptionLink',
          expanded: false,
        },
        {
          icon: 'ProtectedIcon',
          title: 'termsSecureAndPrivateTitle',
          description: 'termsSecureAndPrivateDescription',
          expanded: false,
        },
        {
          icon: 'CheckmarkIcon',
          title: 'termsDataTitle',
          description: 'termsDataDescription',
          expanded: false,
        },
      ],
    };
  },
  computed: {
    clientTitle() {
      return this.$store.state.config.partner_title;
    },
    clientName() {
      return this.$store.state.config.partner_name;
    },
    includesAdditionalTerms() {
      return this.additionalTermsFor.find(
        (customer) => customer === this.clientName,
      );
    },
    customizationText() {
      return this.getConfig.terms_description_text.replace(
        '{company}',
        this.clientTitle,
      );
    },
    ...mapGetters(['getConfig', 'getSelectedSource']),
  },
  mounted() {
    this.el = this.$refs.pageRich;
    this.$nextTick(function () {
      const elScrollID = document.getElementById('pageScroll');
      if (elScrollID) {
        if (elScrollID.scrollHeight > elScrollID.clientHeight) {
          this.showMore = true;
        }
      }
    })
  },
  methods: {
    legalInfoModal(legalType) {
      switch (legalType) {
        case 'moove':
        case 'rollee':
          this.$store.commit('SET_TYPE_OF_TERMS', legalType);
          break;
        case 'privacy':
          this.$store.commit('SET_TYPE_OF_TERMS', legalType);
          break;
        default:
          this.$store.commit('SET_TYPE_OF_TERMS', undefined);
      }
      this.$store.commit('SET_LEGAL_INFO_MODAL', true);
    },
    goNext() {
      this.$store.dispatch('sendConsentAccepted', {
        info: this.consent,
      });
      window?.analytics.track('continue_terms');

      if (this.$store.state.config.partner_name === 'rodeo') {
        window.top.postMessage('Home', '*');
      }
      if (this.getSelectedSource) {
        this.$router.push({ name: 'Login', query: this.$route.query });
      } else {
        this.$router.push({ name: 'Search', query: this.$route.query });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.terms {
  &__info {
    position: relative;
    .logo {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 50%;
      top: 36px;
      transform: translate(-50%, 0)
    }
  }
}
</style>
