<template>
  <div class="login__form">
    <div v-if="getProvider" class="login__form__provider">
      <img :src="getProvider.logo" :alt="getProvider.name">
    </div>
    <div class="login__form-input" v-if="fields.country">
      <div class="input__wrapper" @click="openFilteredCountryModal()">
        <div class="input__country input__country-extended ">
          <template v-if="!getCountrySelected?.name">
            <span class="" />
            <Translate class="input__country-placeholder" :translate="'country'" />
          </template>
          <template v-else>
            <span>{{ getCountrySelected?.flag }}</span>
            <p> {{ getCountrySelected?.name }}</p>
          </template>
          <ArrowDown />
        </div>
      </div>
    </div>
    <div class="input__wrapper--two-fields" v-if="fields.phone">
      <div class="input__wrapper" @click="openCountryModal()">
        <div class="input__country">
          <span>{{ getCountrySelected?.flag }}</span>
          <p>{{ getCountrySelected?.dial_code }}</p>
          <ArrowDown />
        </div>
      </div>
      <div class="input__wrapper">
        <div class="input__phone">
          <input
            type="tel"
            :dir="textDirection"
            v-model="login.credentials"
            @keydown.enter.prevent
            :placeholder="phonePlaceholder"
            data-hj-allow
            class="data-hj-allow"
            @keydown="allowNumbersOnly($event)">
        </div>
      </div>
    </div>
    <div class="login__form-input" v-if="fields.default">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="defaultPlaceholder"
          rows="1" />
      </div>
    </div>
    <div class="login__form-input" v-if="fields.niss">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="nissPlaceholder"
          rows="1" />
      </div>
    </div>
    <div class="login__form-input" v-if="fields.driverLicence">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.password"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          maxlength="14"
          class="data-hj-allow"
          :placeholder="driverLicencePlaceholder"
          rows="1" />
      </div>
    </div>
    <div class="login__form-input" v-if="fields.email">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          @focusout="displayErrorMessage = true"
          @focus="displayErrorMessage = false"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="emailPlaceholder"
          rows="1" />
      </div>
      <div
        class="input__wrapper-error"
        v-if="displayErrorMessage
          && !validateEmail(login.credentials)
          && login.credentials.length > 1"
      >
        <small>
          <Translate :translate="'emailValidation'" />
        </small>
      </div>
    </div>
    <div class="login__form-input" v-if="fields.pps">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="ppsPlaceholder"
          rows="1" />
      </div>
      <div
        class="input__wrapper-error"
        v-if="displayErrorMessage
          && !validatePps(login.credentials)
          && login.credentials.length > 1"
      >
        <small>
          <Translate :translate="'emailValidation'" />
        </small>
      </div>
    </div>
    <div class="login__form-input" v-if="fields.date">
      <div class="input__wrapper" @click="openDateModal()">
        <div class="input__date input__date-extended ">
          <template v-if="login.date">
            <DateIcon/>
            <p> {{ selectedDate }}</p>
          </template>
          <template v-else>
            <DateIcon/>
            <Translate class="input__date-placeholder" :translate="'dateOfBirth'" />
          </template>
        </div>
      </div>
    </div>
    <div class="login__form-input" v-if="fields.emailPhone">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          data-gramm="false"
          :placeholder="emailPhonePlaceholder"
          data-hj-allow
          class="data-hj-allow"
          rows="1" />
      </div>
    </div>
    <div class="login__form-input" v-if="fields.username">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="usernamePlaceholder"
          rows="1" />
      </div>
    </div>
    <div class="login__form-input" v-if="fields.taxId">
        <div class="input__wrapper">
            <input
            type="text"
            :dir="textDirection"
            v-model="login.credentials"
            @focusout="displayErrorMessage = true"
            @focus = "displayErrorMessage = false"
            @keydown.enter.prevent
            :placeholder="
            getSelectedSource?.url === 'autoentrepreneur-rev'
              ? socialSecurityNumberPlaceholder
              : taxIdPlaceholder"
            data-hj-allow
            class="data-hj-allow"
            @keydown="this.getSelectedSource?.url === 'inps-rev' ? null : allowNumbersOnly($event)">
        </div>
        <div class="input__wrapper-error"
             v-if="displayErrorMessage &&
             !validateTaxId(login.credentials)&&
             login.credentials.length > 1"
        >
          <small>
            <Translate :translate="'taxValidation'" />
          </small>
        </div>
    </div>
    <div class="login__form-input" v-if="fields.taxIdInps">
        <div class="input__wrapper">
            <input
            type="text"
            :dir="textDirection"
            v-model="login.credentials"
            @focusout="displayErrorMessage = true"
            @focus = "displayErrorMessage = false"
            @keydown.enter.prevent
            :placeholder="
            getSelectedSource?.url === 'autoentrepreneur-rev'
              ? socialSecurityNumberPlaceholder
              : taxIdInpsPlaceholder"
            data-hj-allow
            class="data-hj-allow"
            @keydown="this.getSelectedSource?.url === 'inps-rev' ? null : allowNumbersOnly($event)">
        </div>
        <div class="input__wrapper-error"
             v-if="displayErrorMessage &&
             !validateTaxId(login.credentials)&&
             login.credentials.length > 1"
        >
          <small>
            <Translate :translate="'taxValidation'" />
          </small>
        </div>
    </div>
    <div class="login__form-input" v-if="fields.url">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="'Domain'"
        />
      </div>
    </div>
    <div class="login__form-input" v-if="fields.apiKey || fields.deviceId">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.password"
          @keydown.enter.prevent
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="fields.apiKey ? 'API-Key' : 'Device ID'"
        />
      </div>
    </div>
    <div class="input__wrapper" v-if="fields.pin">
        <textarea
          :dir="textDirection"
          v-model="login.password"
          @keydown.enter.prevent
          @input="login.password = login.password.toUpperCase()"
          @focusout="displayErrorMessage = true"
          @focus="displayErrorMessage = false"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="pinPlaceholder"
        />
      </div>
    <div class="login__form-input" v-if="fields.emailPass">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.password"
          @keydown.enter.prevent
          @focusout="displayErrorMessage = true"
          @focus="displayErrorMessage = false"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="emailPlaceholder"
          rows="1" />
      </div>
      <div
        class="input__wrapper-error"
        v-if="displayErrorMessage
          && !validateEmail(login.password)
          && login.password.length > 1"
      >
        <small>
          <Translate :translate="'emailValidation'" />
        </small>
      </div>
    </div>
    <div class="login__form-input" v-if="fields.supportNumber">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          @input="login.credentials = login.credentials.toUpperCase()"
          @focusout="displayErrorMessage = true"
          @focus="displayErrorMessage = false"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="nieNumberPlaceHolder"
        />
      </div>
      <div
        class="input__wrapper-error"
        v-if="displayErrorMessage
          && !validateCapitalLetterAndNumber(login.credentials)
          && login.credentials.length > 1"
      >
        <small>
          <Translate :translate="'capitalAlphaNumericValidation'" />
        </small>
      </div>
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.password"
          @keydown.enter.prevent
          @input="login.password = login.password.toUpperCase()"
          @focusout="displayErrorMessage = true"
          @focus="displayErrorMessage = false"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="supportNumberPlaceholder"
        />
      </div>
      <div
        class="input__wrapper-error"
        v-if="displayErrorMessage
          && !validateCapitalLetterAndNumber(login.password)
          && login.password.length > 1"
      >
        <small>
          <Translate :translate="'capitalAlphaNumericValidation'" />
        </small>
      </div>
    </div>
    <div class="login__form-input" v-if="fields.issueDate">
      <div class="input__wrapper">
        <textarea
          :dir="textDirection"
          v-model="login.credentials"
          @keydown.enter.prevent
          @input="login.credentials = login.credentials.toUpperCase()"
          @focusout="displayErrorMessage = true"
          @focus="displayErrorMessage = false"
          data-gramm="false"
          data-hj-allow
          class="data-hj-allow"
          :placeholder="dneNumberPlaceHolder"
        />
      </div>
      <div
        class="input__wrapper-error"
        v-if="displayErrorMessage
          && !validateCapitalLetterAndNumber(login.credentials)
          && login.credentials.length > 1"
      >
        <small>
          <Translate :translate="'capitalAlphaNumericValidation'" />
        </small>
      </div>
      <div class="input__wrapper" @click="openDateModal()">
        <div class="input__date input__date-extended ">
          <template v-if="login.date">
            <DateIcon/>
            <p> {{ selectedDate }}</p>
          </template>
          <template v-else>
            <DateIcon/>
            <Translate class="input__date-placeholder" :translate="'validateDateOrIssueDate'" />
          </template>
        </div>
      </div>
    </div>
    <div class="input__wrapper input__wrapper--has-icon-right" v-if="fields.password">
      <input
        :dir="textDirection"
        :type="passwordShow ? 'text' : 'password'"
        class="login__form-password-input"
        v-model="login.password"
        @keydown.enter.prevent
        data-gramm="false"
        autocomplete="new-password"
        :placeholder="passwordPlaceholder"
        />
      <VisibleIcon
        class="input__icon"
        :class="{ 'input--disabled': login.password.length === 0 }"
        @click="passwordShow = !passwordShow"
      />
    </div>
    <div class="input__wrapper input__wrapper--has-icon-right" v-if="fields.passwordInps">
      <input
        :dir="textDirection"
        :type="passwordShow ? 'text' : 'password'"
        class="login__form-password-input"
        v-model="login.password"
        @keydown.enter.prevent
        data-gramm="false"
        autocomplete="new-password"
        :placeholder="passwordInpsPlaceholder"
        />
      <VisibleIcon
        class="input__icon"
        :class="{ 'input--disabled': login.password.length === 0 }"
        @click="passwordShow = !passwordShow"
      />
    </div>

    <DatePickerModal
    @confirm="setDate($event.isoDate);selectedDate = $event.date"
    v-if="getDateModal">
    </DatePickerModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import VisibleIcon from '@/components/icons/VisibleIcon';
import ArrowDown from '@/components/icons/ArrowDown';
import DateIcon from '@/components/icons/DateIcon';
import Localization from '@/mixins/localization';
import DatePickerModal from './DatePickerModal';
export default {
  name: 'LoginFields',
  components: {
    VisibleIcon,
    ArrowDown,
    DatePickerModal,
    DateIcon,
  },
  props: {
    tabName: {
      type: String,
      required: false,
    },
  },
  mixins: [Localization],
  data() {
    return {
      passwordShow: false,
      passwordSwitchEnabled: false,
      selectedDate: '',
      login: {
        credentials: '',
        password: '',
        date: undefined,
      },
      fields: {
        country: false,
        default: false,
        pps: false,
        date: false,
        pin: false,
        username: false,
        email: false,
        emailPass: false,
        emailPhone: false,
        password: false,
        phone: false,
        taxId: false,
        supportNumber: false,
        apiKey: false,
        url: false,
        issueDate: false,
        taxIdInps: false,
        passwordInps: false,
        niss: false,
        driverLicence: false,
      },
      displayErrorMessage: false,
      loginFields: [],
    };
  },
  mounted() {
    this.resetFields();
    this.setInputFields();
    this.formatPhone();
  },
  computed: {
    ...mapGetters([
      'getSelectedSource',
      'getCountryModal',
      'getCountrySelected',
      'getError',
      'getCountries',
      'getConfig',
      'getLoginCredentials',
      'getDateModal',
      'getProvider'
    ]),
    passwordPlaceholder() {
      return this.$store.state.translations.password;
    },
    emailPlaceholder() {
      return this.$store.state.translations.email;
    },
    ppsPlaceholder() {
      return this.$store.state.translations.ppsNumber;
    },
    defaultPlaceholder() {
      return this.$store.state.translations.defaultLogin;
    },
    emailPhonePlaceholder() {
      return this.$store.state.translations.emailPhone;
    },
    usernamePlaceholder() {
      return this.$store.state.translations.username;
    },
    phonePlaceholder() {
      return this.$store.state.translations.phone;
    },
    taxIdPlaceholder() {
      return this.$store.state.translations.taxId;
    },
    taxIdInpsPlaceholder() {
      return this.$store.state.translations.taxIdInps;
    },
    passwordInpsPlaceholder() {
      return this.$store.state.translations.passwordInps;
    },
    dneNumberPlaceHolder() {
      return this.$store.state.translations.dneNumber;
    },
    nieNumberPlaceHolder() {
      return this.$store.state.translations.nieNumber;
    },
    supportNumberPlaceholder() {
      return this.$store.state.translations.supportNumber;
    },
    pinPlaceholder() {
      return this.$store.state.translations.pin;
    },
    socialSecurityNumberPlaceholder() {
      return this.$store.state.translations.socialSecurity;
    },
    nissPlaceholder() {
      return this.$store.state.translations.niss;
    },
    driverLicencePlaceholder() {
      return this.$store.state.translations.driverLicence;
    },
  },
  /* eslint-disable */
  watch: {
    login: {
      handler(login) {
        this.callInputValidity(login);
      },
      deep: true,
    },
    getCountrySelected() {
      this.callInputValidity(this.login);
    },
    getProvider() {
      this.setInputFields();
    },
  },
  methods: {
  openDateModal() {
    this.$store.commit('SET_DATE_MODAL', true);
  },
    /* eslint-disable */
    setInputFields() {
      this.resetFields();
      if(this.getProvider) {
        this.loginFields = this.getProvider['login-fields'];
      } else {
        this.loginFields = this.getSelectedSource['login-fields'];
      }
      if (this.loginFields === null || this.loginFields === undefined) {
        this.fields.default = true;
        this.fields.password = true;
        return;
      }
      const fieldForTab = this.loginFields.filter((field) => field.tab === this.tabName);
      fieldForTab.forEach((item) => {
        switch (true) {
          case item.field === 'country':
            this.fields.country = true;
            break;
          case item.field === 'phone':
            this.fields.phone = true;
            break;
          case item.field === 'password':
            this.fields.password = true;
            break;
          case item.field === 'passwordInps':
            this.fields.passwordInps = true;
            break;
          case item.field === 'pin':
            this.fields.pin = true;
            break;
          case item.field === 'emailPhone':
            this.fields.emailPhone = true;
            break;
          case item.field === 'pps':
            this.fields.pps = true;
            break;
          case item.field === 'date':
            this.fields.date = true;
            break;
          case item.field === 'email':
            this.fields.email = true;
            break;
          case item.field === 'emailPass':
            this.fields.emailPass = true;
            break;
          case item.field === 'username':
            this.fields.username = true;
            break;
          case item.field === 'taxId':
            this.fields.taxId = true;
            break;
          case item.field === 'taxIdInps':
            this.fields.taxIdInps = true;
            break;
          case item.field === 'apiKey':
            this.fields.apiKey = true;
            break;
          case item.field === 'deviceId':
            this.fields.deviceId = true;
            break;
          case item.field === 'issueDate':
            this.fields.issueDate = true;
            break;
            case item.field === 'supportNumber':
            this.fields.supportNumber = true;
            break;
          case item.field === 'url':
            this.fields.url = true;
            break;
          case item.field === 'niss':
            this.fields.niss = true;
            break;
          case item.field === 'driverLicence':
            this.fields.driverLicence = true;
            break;
          default:
            this.fields.default = true;
        }
      });
      if (!this.fields.country) {
        this.setDefaultCountry();
      }
    },
    setDate(date) {
      this.login.date = date;
    },
    checkFieldValidity() {
      let isValid = true;
      if (this.fields.email) {
        isValid = this.validateEmail(this.login.credentials) && this.login.password.length > 0;
      }
      if (this.fields.username) {
        isValid = this.validateUser(this.login.credentials) && this.login.password.length > 0;
      }
      if(this.fields.pps) {
        isValid = this.validatePps(this.login.credentials) && this.login.password.length > 0 && this.login.date?.length > 0;
      }
      if (this.fields.phone) {
        // Check if password is required
        if(this.fields.password){
          isValid = this.validatePhone(this.login.credentials) && this.login.password.length > 0;
        }
        else if (this.fields.emailPass){
          isValid = this.validatePhone(this.login.credentials) && this.validateEmail(this.login.password);
        }
        else {
          isValid = this.validatePhone(this.login.credentials);
        }
      }
      if(this.fields.pin){
        isValid = this.login.password.length === 4;
      }
      if (this.fields.emailPhone) {
        isValid = this.validateEmailPhone(this.login.credentials) && this.login.password.length > 0;
      }
      if(this.fields.taxId || this.fields.taxIdInps) {
        isValid = this.validateTaxId(this.login.credentials) && this.login.password.length > 0;
      }
      if(this.fields.country) {
        isValid = this.validateEmailPhone(this.login.credentials) && this.getCountrySelected?.code;
      }
      if(this.fields.supportNumber) {
        isValid = this.validateCapitalLetterAndNumber(this.login.credentials) && this.validateCapitalLetterAndNumber(this.login.password);
      }
      if(this.fields.issueDate) {
        isValid = this.validateCapitalLetterAndNumber(this.login.credentials) && this.login.date?.length > 0;
      }
      if (this.fields.default) {
        isValid = this.validateEmailPhone(this.login.credentials) && this.login.password.length > 0;
      }
      if (this.fields.apiKey && !this.fields.url) {
        isValid = this.login.password.length > 0;
      }
      if (this.fields.apiKey && this.fields.url) {
        isValid = this.login.password.length > 0;
      }
      if (this.fields.niss) {
        isValid = this.login.credentials.length > 0 && this.login.password.length > 0;
      }
      if (this.fields.driverLicence) {
        isValid = this.validatePhone(this.login.credentials) && this.login.password.length > 0;
      }
      return isValid;
    },
    callInputValidity: debounce(function (login){
      if (this.checkFieldValidity()) {
        const loginMap = {
          username: login.credentials,
          password: login.password,
          date: login.date,
          type: 'default',
          provider: this.getProvider?.type,
        };
        if (this.fields.phone) {
          if (loginMap.username.length > 10 && Number(loginMap.username[0]) === 0 && this.phone) {
            loginMap.username = this.phone?.substring(1);
          }
          loginMap.username = `${this.getCountrySelected.code}-${loginMap.username}`;
          loginMap.type = 'phone';
          if (this.fields.country) {
            loginMap.country = this.getCountrySelected?.code;
          }
          if (this.fields.driverLicence) {
            loginMap.type = 'driver_licence';
          }
        }
        else if(this.fields.email) {
          loginMap.type = 'email';
          if (this.fields.country) {
            loginMap.country = this.getCountrySelected?.code;
          }
        }
        else if(this.fields.taxId) {
          loginMap.type = 'tax_id';
        }
        else if(this.fields.apiKey) {
          loginMap.type = 'api_key';
        }
        else if(this.fields.deviceId) {
          loginMap.type = 'device_id';
        }
        else if(this.fields.supportNumber) {
          loginMap.type = 'support_number';
        }
        else if(this.fields.emailPass) {
          loginMap.type = 'phone_email';
        }
        this.$emit('login', loginMap);
        this.$emit('continueEnabled', true);
        return;
      }
      this.$emit('continueEnabled', false);
    }, 200),
    openCountryModal() {
      this.$store.commit('SET_COUNTRY_MODAL', true);
    },

    openFilteredCountryModal() {
      this.$store.commit('SET_FILTER_COUNTRY_MODAL', true);
      this.$store.commit('SET_COUNTRY_MODAL', true);
    },
    setDefaultCountry() {
      // Check if country selected by user and escape if true
      if (this.getCountrySelected?.code) {
        return;
      }
      // Check if no phone code is send in config and set default
      if (!this.$store.state.config?.phone_code) {
        this.$store.commit('SET_COUNTRY_SELECTED', this.$store.state.dialCodes[0]);
        return;
      }
      // Set phone code send via config
      // eslint-disable-next-line
      const country = this.getCountries.find((countries) => countries.dial_code === this.$store.state.config.phone_code);
      this.$store.commit('SET_COUNTRY_SELECTED', country);
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    validatePhone(phone) {
      return String(phone).match(/^\+?[0-9]{6,15}$/);
    },
    validateTaxId(taxID) {
      const taxIDString = String(taxID);
      switch (this.getSelectedSource.url) {
        case 'inps-rev':
          // regex Capital letter and 0-9
          return taxIDString.match(/^[a-zA-Z0-9]*$/) && taxIDString.length >= 13 && taxIDString.length <= 16;
        case 'portal-financas-rev':
          // Remove any non-digit characters from the tax ID
          const cleanedTax9ID = taxIDString.replace(/\D/g, '');
          // Validate that the cleaned tax ID is exactly 9 digits
          return cleanedTax9ID.length === 9;
        default:
        // Remove any non-digit characters from the tax ID
        const cleanedTaxID = taxIDString.replace(/\D/g, '');
        // Validate that the cleaned tax ID is exactly 13 digits
        return cleanedTaxID.length === 13;
      }
    },
    validatePps(ppsNumber) {
      const ppsNumberString = String(ppsNumber)
      return ppsNumberString.match(/^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]*$/) && ppsNumberString.length >= 8 && ppsNumberString.length <= 9;
    },
    validateUser(username) {
      return String(username).length > 0;
    },
    validateEmailPhone(emailPhone) {
      return String(emailPhone).length > 0;
    },
    validateCapitalLetterAndNumber(value, length = 9) {
      // Regex that all A-Z are capitalized or 0-9 is present and length is equal to argument
      return String(value).match(/^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]*$/) && String(value).length === length;

    },
    allowNumbersOnly(event) {
      const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      if (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 13) {
        return;
      }
      if (event.keyCode === 86 && event.ctrlKey) {
        return;
      }
      if(!numbers.includes(event.key)) {
        event.preventDefault();
      }
    },
    formatPhone() {
      if (!this.getLoginCredentials?.username) {
        return;
      }
      if (this.fields.phone) {
        this.login.credentials = this.getLoginCredentials?.username.split('-')[1];
        return;
      }
      this.login.credentials = this.getLoginCredentials?.username;
    },

    resetFields(){
      Object.keys(this.fields).forEach((key) => {
        this.fields[key] = false;
      });
    }
  },
};
</script>

<style scoped lang="scss">
.login{
  &__form {

    &__provider{
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 100px;
        height: 30px;
      }
    }

    &-password {
      font-family: 'text-security-disc' !important;
      font-size: 17px !important;
      -webkit-text-security: disc !important;
      &::placeholder {
        font-size: 17px;
        font-family: 'Gilroy', sans-serif;
      }
      &.show-password {
        font-size: 17px !important;
        font-family: 'Gilroy', sans-serif !important;
        -webkit-text-security: none !important;
      }
    }
  }
}
.input{
  &__wrapper{
    &-error{
      text-align: start;
      padding-left: 12px;
      transform: translateY(-10px);
      color: #F52922;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
</style>
