<template>
	<div class="modal__wrapper">
		<div class="modal modal--general">
			<div class="modal__header">
				<CloseIcon
					class="modal__close"
					@click="closeModal()" />
				<h2 class="modal__heading">{{ title }}</h2>
			</div>
			<div class="modal__content">
				<slot />
			</div>
		</div>
	</div>
</template>
<script>
import CloseIcon from '@/components/icons/CloseIcon';
import General from "@/mixins/general";
export default {
	name: 'Modal',
  mixins: [General],
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	components: {
		CloseIcon,
	},
	methods: {
		closeModal() {
			this.$store.dispatch('setLoadMoreModal', false);
			this.$store.dispatch('setTaxModal', false);
			this.$store.dispatch('setDateModal', false);
		},
	},
};
</script>
