<template>
	<div class="modal__wrapper center">
		<div class="modal modal--date">
			<div class="modal__content">
        <div class="date-picker"></div>
			</div>
      <div class="modal__actions">
        <button
          class="button--secondary"
          @click="closeModal"
          @keydown.enter="closeModal">
          <Translate :translate="'close'" />
        </button>
        <button
          class="button--primary"
          @click="confirm"
          @keydown.enter="login">
          <Translate :translate="'confirm'" />
        </button>
      </div>
		</div>
	</div>
</template>
<script>
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en'

import General from "@/mixins/general";
import { mapGetters } from 'vuex';
export default {
	name: 'DatePickerModal',
  mixins: [General],
  data() {
    return {
      state: {
        date: undefined,
        isoDate: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['getSelectedSource']),
  },
  mounted() {
    this.datePicker = new AirDatepicker('.date-picker', {
      inline: true,
      locale: localeEn,
      isMobile: true,
      maxDate: this.getSelectedSource?.url === 'ros' ? new Date() : undefined,
      onSelect: (event) => {
        if(!event.date) return;
        const date = new Date(event.date);
        const localIso = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
        this.state.isoDate = localIso;
        // Format date to DD-MM-YYYY format and local timezone
        this.state.date = date.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      },
    });
  },
	methods: {
		closeModal() {
			this.$store.dispatch('setDateModal', false);
		},
    confirm() {
      this.$emit('confirm', this.state);
      this.$store.dispatch('setDateModal', false);
    },
	},
};
</script>
<style lang="scss" scoped>
.modal{
  &__content{
    height: 236px;
  }
  &__actions{
    display: grid;
    border-top: 2px solid #EDF2F8;
    margin: 16px 16px 0 16px;
    padding-top: 16px;
    grid-template-columns: 1fr 2fr;
    column-gap: 12px;
  }

}
.date-picker{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
