<template>
  <div id="app__wrapper" class="app__wrapper">
    <div class="content__section">
      <router-view v-slot="{ Component }">
        <transition :name="transitionName" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
      <CloseModal />
      <FacebookModal />
      <ApprovalModal v-if="getApprovalModal" />
      <TermsOfUseModal v-if="getTermsOfUseModal" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import General from "@/mixins/general";
// eslint-disable-next-line
import KeyHoleIcon from '@/components/icons/KeyHoleIcon.vue';
// eslint-disable-next-line
import ProtectedIcon from '@/components/icons/ProtectedIcon.vue';
// eslint-disable-next-line
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue';
import CloseIcon from '@/components/icons/CloseIcon';
import CloseModal from '@/components/CloseModal';
import CodeModal from '@/components/CodeModal';
import FacebookModal from '@/components/FacebookModal';
import Data from '@/api/data';
import ApprovalModal from '@/components/ApprovalModal';
import TermsOfUseModal from '@/components/TermsOfUseModal';
import helpers from '@/helpers/helpers';
import http from '@/api/http';
import types from '@/store/mutationTypes';
import Analytics from '@/api/analytics';
import Hotjar from '@/api/hotjar';
import i18n from '@/i18n';
import { isEmpty } from 'lodash';

export default {
  name: 'App',
  components: {
    ApprovalModal,
    TermsOfUseModal,
    CloseModal,
    CodeModal,
    FacebookModal,
    KeyHoleIcon,
    ProtectedIcon,
    CheckmarkIcon,
    CloseIcon,
  },
  mixins: [General],
  data() {
    return {
      transitionName: undefined,
      firstLoad: false,
      authPages: [
        'Fetch',
        'Connect',
      ],
      uuidFromUrl: undefined,
      initAnalyticsCalled: false,
      trackingId: undefined,
      termsQA: [
        {
          icon: 'KeyHoleIcon',
          title: 'termsPlatformTitle',
          description: 'termsPlatformDescription',
          descriptionLink: 'termsPlatformDescriptionLink',
          expanded: false,
        },
        {
          icon: 'ProtectedIcon',
          title: 'termsSecureAndPrivateTitle',
          description: 'termsSecureAndPrivateDescription',
          expanded: false,
        },
        {
          icon: 'CheckmarkIcon',
          title: 'termsDataTitle',
          description: 'termsDataDescription',
          expanded: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getApprovalModal',
      'getAvailableSources',
      'getConnectedSources',
      'getTermsOfUseModal',
      'getSelectedSource',
      'sources',
      'getClientName',
      'getAppUUID',
      'getAccountId',
      'getFetchUUID',
      'getConfig',
      'getLoginCredentials',
    ]),
  },
  async mounted() {
    document.title = process.env.VUE_APP_TITLE || 'Rollee Connect';
    this.initWoker();
    const getUUID = () => {
      const path = window.location.pathname.split('/');
      if (path[3]?.length < 36) {
        return false;
      }
      return path[3];
    };
    if (getUUID()) {
      this.uuidFromUrl = getUUID();
      this.checkFetchStatus();
    }
    window.onmessage = async (e) => {
      if (e.data.action === 'setConfig') {
        const configFromPostMessage = JSON.parse(e.data.value);
        this.$store.state.config.search_available_datasources = null;
        _.merge(this.$store.state.config, configFromPostMessage);
        const translations = (await i18n.common(this.$store.state.config.lang)).default;
        this.$store.state.translations = translations;
      }
      if (e.data.action === 'setPage') {
        this.$router.push({ name: e.data.value });
      }
      if (e.data.action === 'setDatasources') {
        this.$store.state.sources.sources = JSON.parse(e.data.value);
      }
    };
    window.top.postMessage('AppLoaded', '*');
  },
  created() {
    if (process.env.VUE_APP_HOTJAR_ENABLED === 'true') {
      Hotjar.init();
    }
    if (process.env.VUE_APP_SMARTLOOK_ENABLED === 'true') {
      // ADD SMARTLOOK INIT
      window.smartlook('init', '687f321b1d9d0fac10955f30c2cf5fd6f1b6bbb5', { region: 'eu' });
      window.smartlook('record', { forms: true, numbers: true, emails: true, ips: true })
    }
    if(process.env.VUE_APP_LOG === 'true') {
      const currentLog = [];
      window.onerror = (err, url, line) => {
          currentLog.push({
            error: err,
            url: url,
            line: line
          });
          // Dispatch the error to the analytics
          Analytics.dispatch({
            action: 'runtimeError',
            fromScreen: 'RuntimeError',
            toScreen: 'RuntimeError',
            status: 'runtime_error',
            log: `Runtime ${JSON.stringify(currentLog)}`,
          });
          return false;   // pass the error to the default handler
      };
    }
    http.interceptors.response.use(undefined, (err) => {
      switch (err.response?.status) {
        case 401:
          this.$store.commit(types.SET_ERROR, {
            message: 'Your session has expired!',
            description: 'Your session has timed out for security purposes, please try again or contact your administrator',
            show: true,
          });
          this.$router.push({ name: 'Error' });
          break;
        default:
      }
      return err;
    });
  },
  methods: {
    ...mapActions(['getSources', 'getConfiguration', 'getSessionInfo']),
    setTransition(to, from) {
      if (this.firstLoad && to.meta.animation) {
        this.transitionName = to.meta.index > from.meta.index ? 'next' : 'prev';
      } else {
        this.transitionName = '';
      }
      this.firstLoad = true;
    },
    checkFetchStatus() {
      this.getSources().then(() => {
        this.sources?.sources?.forEach((source) => {
          if (source['last-fetch'] && typeof source['last-fetch'] === 'object') {
            if (source['last-fetch'].uuid && !source['last-fetch'].status) {
              this.$store.commit('SET_SELECTED_SOURCE', source);
              this.$store.commit('SET_REFRESHED_UUID', source['last-fetch'].uuid);
              this.$store.commit('SET_AUTH', true);
              this.$router.push({
                name: 'Fetch',
                query: this.$route.query
              });
            }
          }
          if(this.getAvailableSources?.sources?.length === 1 && !this.$store.state.selectedSource) {
            this.$store.commit('SET_SELECTED_SOURCE', this.filteredSource());
          }
          if (source.ID === this.$store.state.config.datasource) {
            this.$store.commit('SET_SELECTED_SOURCE', source);
          }
        });
      });
    },
    initWoker() {
      if (window.Worker) {
        const worker = new Worker(`${process.env.VUE_APP_BASE_URL}js/session.worker.js`);
        window.rolleeWorker = worker;
        worker.addEventListener('message', this.workerResponseHandler);
      }
    },
    initAppAnalytic(routeName) {
      // Create unix time stamp for BA
      this.trackingId = helpers.unixTimeStamp();
      // Initial call to show how many users opened the app
      const payload = {
        type: 'events',
        data: {
          datasource_id: this.getSelectedSource?.ID ? this.getSelectedSource.ID : '',
          device: this.isMobile ? 'mobile' : 'desktop',
          duration: null,
          previous_screen: null,
          screen: routeName,
          status: '',
          status_description: '',
          tracking_id: this.trackingId,
          userId: this.getConfig.person_id,
          auth_method: this.getLoginCredentials?.type,
        },
      };
      Data.analytics(payload).then(() => {
        // Disable flag for init analytics
        this.initAnalyticsCalled = true;
      }).catch(() => {
        this.initAnalyticsCalled = true;
      });
      // Send analytics data to onAnalytics callback
      Analytics.callback(payload);
    },
    workerResponseHandler(e) {
      switch (e.data.action) {
        case 'getSessionDuration':
        case 'fetchingError':
          if (e.data.fromScreen || e.data.toScreen) {
            let STATUS;
            let STATUS_DESCRIPTION;
            if (e.data.action === 'fetchingError') {
              STATUS = e.data.status;
              STATUS_DESCRIPTION = e.data.errorType;
            }
            const payload = {
              type: 'events',
              data: {
                datasource_id: this.getSelectedSource?.ID ? this.getSelectedSource.ID : '',
                device: this.isMobile ? 'mobile' : 'desktop',
                duration: e.data.duration,
                previous_screen: e.data.fromScreen,
                screen: e.data.toScreen,
                status: STATUS || '',
                status_description: e.data.status_description || STATUS_DESCRIPTION || '',
                account_id: this.getAccountId,
                tracking_id: this.trackingId,
                fetch_id: this.getFetchUUID,
                userId: this.getConfig.person_id,
                auth_method: this.getLoginCredentials?.type,
              },

            };
            if (process.env.VUE_APP_ENABLE_TRACKING === 'true') {
              Data.analytics(payload);
              // Send analytics data to onAnalytics callback
              Analytics.callback(payload);
            }
          }
          break;
        case 'runtimeError':
        if (e.data.fromScreen || e.data.toScreen) {
            const payload = {
              type: 'events',
              data: {
                datasource_id: this.getSelectedSource?.ID ? this.getSelectedSource.ID : '',
                device: this.isMobile ? 'mobile' : 'desktop',
                duration: e.data.duration,
                previous_screen: e.data.fromScreen,
                screen: e.data.toScreen,
                status: 'runtime-error' || '',
                status_description: e.data.log || '',
                account_id: this.getAccountId,
                tracking_id: this.trackingId,
                fetch_id: this.getFetchUUID,
                userId: this.getConfig.person_id,
                auth_method: this.getLoginCredentials?.type,
              },

            };
            Data.analytics(payload);
          }
          break;

        case 'fetchingStarted':
          this.$store.commit('SET_ERROR', {
            name: 'Error',
            description: this.$store.state.errorConnectingDataSource,
          });
          this.$router.push({
            name: 'Login',
            query: this.$route.query
          });
          break;
        default:
          break;
      }
    },
    closeApp() {
      window?.analytics.track('close_' + this.$router.currentRoute.value.name.toLowerCase());
      this.$store.commit('SET_CLOSE_MODAL_ENABLED', true);
    },
    legalInfoModal(legalType) {
      switch (legalType) {
        case 'moove':
        case 'rollee':
          this.$store.commit('SET_TYPE_OF_TERMS', legalType);
          break;
        case 'privacy':
          this.$store.commit('SET_TYPE_OF_TERMS', legalType);
          break;
        default:
          this.$store.commit('SET_TYPE_OF_TERMS', undefined);
      }
      this.$store.commit('SET_LEGAL_INFO_MODAL', true);
    },
    filteredSource() {
      if (this.getAvailableSources.sources !== null && !isEmpty(this.getAvailableSources) && this.getAvailableSources.sources !== undefined) {
        // eslint-disable-next-line
        const reducedSources = this.getAvailableSources.sources.filter((source) => {
          // eslint-disable-next-line
          if(source.multiple){
            return true;
          }
          // filter reducedSources by last-fetch if complete filter out
          if ((source['last-fetch'] && source['last-fetch'].status === 'complete') && !source.multiple) {
            return false;
          }

          if (!this.getConnectedSources?.some(
            (connectedSource) => connectedSource.name === source.name,
          )) {
            return true;
          }
          return false;
        });
        return reducedSources[0];
      }
      return null;
    },
  },
  watch: {
    $route(to, from) {
      window.top.postMessage(to.name, '*');

      if (!this.initAnalyticsCalled && process.env.VUE_APP_ENABLE_TRACKING === 'true') {
        this.initAppAnalytic(to.name);
      }
      // eslint-disable-next-line
      window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage(to.name);
      this.setTransition(to, from);
      if (from.name) {
        window.rolleeWorker.postMessage({
          action: 'getSessionDuration',
          fromScreen: from.name,
          toScreen: to.name,
          screenID: from.meta.index,
        });
      }
      // Emit analytic event when navigating to the error page
      // ? from.name is empty
      if (to.name === 'Error') {
        window.rolleeWorker.postMessage({
          action: 'getSessionDuration',
          fromScreen: 'Init',
          toScreen: to.name,
          screenID: from.meta.index,
        });
      }
      if (to.name === 'Fetch') {
        window.rolleeWorker.postMessage({
          action: 'fetchingStarted',
        });
      } else {
        window.rolleeWorker.postMessage({
          action: 'stopFetching',
        });
      }
    },
  },
};
</script>
<style lang="scss">
html {
  height: calc(100vh - calc(100vh - 100%));
}

body {
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  color: $text-color-default;
  height: calc(100vh - calc(100vh - 100%));
  background: $blue-background;
}

* {
  margin: 0;
}

#app {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  @include respond-below(md) {
    display: block;
  }
}

.app {
  &__wrapper {
    width: $app-width;
    height: $app-height;
    max-height: 100%;
    background: $app-background-color;
    box-shadow: 0px 8px 80px rgba(20, 20, 20, 0.08);;
    border-radius: $app-border-radius;
    overflow: hidden;

    &.desktop{
      width: 100%;
      max-width: 1366px;
      height: 100%;
      height: 768px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: 1px solid $grey-outline;
      box-shadow: 0px 20px 150px 0px rgba(20, 20, 20, 0.04);
      border-radius: 20px;
    }
    @include respond-below(md) {
      width: 100%;
      height: 100%;
      border-radius: initial;
      margin: 0px;
    }
    @include respond-below-height(md) {
      overflow: hidden;
      max-height: 100vh;
    }
  }
}
.desktop {
  & .page{
    &--terms,
    &--fetch,
    &--connect,
    &--login,
    &--success,
    &--error,
    &--contact{
      padding: 24px 80px 40px 80px;
    }

    &__details{
      max-height: 320px;
      margin: 12px 0;
    }

    &__rich{
      &--small{
        max-height: 270px;
      }
      &--expand{
        max-height: unset;
      }
    }
    &--terms,
    &--login{
      grid-template-rows: 100px auto;
      & .header{
        height: 100px;
        align-items: flex-start;
        justify-content: flex-start;

        & .back{
          align-items: flex-start;
          transform: translateX(-40px);
        }
      }
    }

    &--login{
      .login__footer-wrapper{
        position: absolute;
        bottom: 40px;
        padding: 0 80px;
      }
    }

    &--contact{
      & .header{
        height: 100px;
        align-items: flex-start;
        justify-content: flex-start;
        & .back{
          align-items: flex-start;
          transform: translateX(-40px);
        }
      }
    }
    &--connect{
      grid-template-rows: 100px minmax(200px, 600px);
      & .sources{
        height: 100%;
      }
      & .header{
        height: 100px;
        align-items: flex-start;
        justify-content: flex-start;
        & .back{
          align-items: flex-start;
          transform: translateX(-40px);
        }
      }

      & .connect__sources{
        transform: translate(-80px, 45px);
        width: calc(100% + 160px);
        padding: 8px 40px
      }
    }
    &--success{
      & .header{
        height: 100px;
        align-items: flex-start;
        justify-content: flex-start;
        & .back{
          align-items: flex-start;
          transform: translateX(-40px);
        }
      }

      & .connect__sources{
        transform: translate(8px, 10px);
        width: calc(100% + 170px);
        padding: 8px 42px;
      }
    }
  }

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url(../public/img/background.svg) center center no-repeat;
    background-position: right;
    flex-direction: column;
    height: 768px;
    padding: 0 80px;

    &__item{
      display: grid;
      grid-template-columns: 32px 1fr;
      width: 100%;
      column-gap: 12px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      align-items: center;
      border-bottom: 1px solid $grey-outline;
      &-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
      }
      &-title{
        color: $black;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
      }
      &-description{
        grid-column-end: -1;
        color: $dark-grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
  &__actions{
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
.content{
  &__section {
    position: relative;
    height: 100%;
  }
}
</style>
