<template>
  <header class="header">
    <BackIcon class="header__back" v-if="hasBack" @click="goBack" v-show="getPredefinedSource" />
    <img class="header__logo" v-if="hasLogo" :src="logo" alt="">
    <h3 class="header__title" v-if="hasTitle">
      <Translate :translate="title" />
    </h3>
    <CloseIcon
      class="header__close"
      @click="closeApp()"
      v-if="hasClose"
      v-show="!getHeaderCloseButton" />
  </header>
</template>

<script>
import CloseIcon from './icons/CloseIcon';
import BackIcon from './icons/BackIcon';
export default {
  name: 'Header',
  components: {
    CloseIcon,
    BackIcon,
  },
  data() {
    return {
      assetsURL: process.env.VUE_APP_API_CONNECT_PATH,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
    hasLogo: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasConnected() {
      return this.$store.state.connectedSources.length > 0;
    },
    logo() {
      if (this.$store.state.config.partner_logo.indexOf('data:image') === 0) {
        return `${decodeURIComponent(this.$store.state.config.partner_logo)}`;
      }
      if (this.$store.state.config.partner_logo.indexOf('http') === 0) {
        return this.$store.state.config.partner_logo;
      }
      return `${this.assetsURL}${this.$store.state.config.partner_logo}`;
    },
    getHeaderCloseButton() {
      return this.$store.state.config.remove_close_button;
    },
    getPredefinedSource() {
      return !this.$store.state.config.datasource;
    },
  },
  methods: {
    closeApp() {
      window?.analytics.track('close_' + this.$router.currentRoute.value.name.toLowerCase());
      this.$store.commit('SET_CLOSE_MODAL_ENABLED', true);
    },
    goBack() {
      window?.analytics.track('back_' + this.$router.currentRoute.value.name.toLowerCase());
      this.$emit('goBack', true);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: grid;
  align-content: center;
  width: 100%;
  height: $header-height;
  position: relative;
  justify-content: center;

  &__title {
    text-align: center;
    color: $text-color-dark;
    font-size: $header-title-font-size;
    padding: 0 6px;
  }

  &__close {
    color:#A8AFBC;
    position: absolute;
    top: 0;
    right: 5px;
    height: 100%;
  }

  &__back {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__logo {
    height: 24px;
    max-height: 24px;
  }
}
</style>
